import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { UploadFile } from 'app/components/Inputs/UploadFile';
import { useForm } from 'react-hook-form';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { useAddFileMutation } from '../../services';
import {
  UploadFileType,
  uploadFile,
  processFileResponse,
} from '../../utils/fileUpload.utils';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getAcceptedMimeTypes } from 'utils/files';
import { FileUploadConfig } from 'app/pages/vault/models/vault.types';
import * as styles from './style';

type Props = {
  isOpen: boolean;
  fileUploadConfig: FileUploadConfig | null;
  onClose: () => void;
};

export const ModalFileUpload = ({
  isOpen,
  fileUploadConfig,
  onClose,
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    unregister,
    watch,
    setValue,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const [addFile] = useAddFileMutation();
  const uploadedFiles = watch('uploadedFiles', []);
  const accept = getAcceptedMimeTypes();

  const handleFileUpload = async () => {
    if (
      !fileUploadConfig ||
      !fileUploadConfig.folder ||
      !fileUploadConfig.callback
    ) {
      return;
    }

    setIsUploading(true);

    const { folder, callback } = fileUploadConfig;
    const uploadPromises = uploadedFiles.map(
      async (uploadedFile: UploadFileType) => {
        const putUrl = await uploadFile(uploadedFile, folder, addFile);
        processFileResponse(putUrl, uploadedFile, folder, callback);
      },
    );

    await Promise.all(uploadPromises);

    setIsUploading(false);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h5" mb={3} mt={3}>
            {t('vault.modals.fileUpload.title')}
          </Typography>
          <UploadFile
            accept={accept}
            register={register}
            errors={errors}
            setValue={setValue}
            unregister={unregister}
            watch={watch}
            control={control}
            resetField={resetField}
            name="uploadedFiles"
            disclaimer="PDF, DOCS, SVG, PNG, JPG or GIF (max. 200MB)"
          />
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={handleCancel}
            onSecondaryBtnClick={handleFileUpload}
            primaryBtnText={t('vault.modals.fileUpload.buttons.cancel')}
            secondaryBtnText={t('vault.modals.fileUpload.buttons.upload')}
            secondaryBtnDisabled={!uploadedFiles || isUploading}
          />
        </Card>
      </Box>
    </Modal>
  );
};
